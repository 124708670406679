import { Container, Col, Row } from 'react-bootstrap';
import iconinwalifa from '../Images/iconinwalifa.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Contact.css';

function Contact(props) {
    return (
        <div className="contact-body">
            <Container>
                <div className="contact-container">
                    <Row style={{ marginTop: props.soloSite ? "100px" : "none" }}>
                        <Col lg={5}>
                            <div className="contact-title">
                                Kontakt
                            </div>
                            <div className="contact-line">
                                <hr />
                            </div>
                            <div className="contact-content">
                                <p>
                                    Kancelaria Adwokacka
                                    <br />
                                    Adwokat Jakub Boruta
                                    <br />
                                    ul. Kraszewskiego 28 lok. U5, 15-025 Białystok
                                </p>
                                <p>
                                    Tel. 660 717 588
                                    <br />
                                    email:&nbsp;
                                    <a href="mailto:jakub@adwokatboruta.pl">jakub@adwokatboruta.pl</a>
                                </p>
                            </div>
                            <div className="contact-info">
                                <div className="contact-info-text">
                                    Klientów obsługujemy w języku polskim, rosyjskim i angielskim
                                </div>
                                <div className="contact-info-img">
                                    <img
                                        decoding="async"
                                        //src="https://adwokatboruta.pl/wp-content/uploads/2021/11/iconinwalifa.png" 
                                        src={iconinwalifa}
                                        alt=""
                                        loading="lazy"
                                        width="39"
                                        height="43"
                                    />
                                </div>
                                <div className="contact-info-text">
                                    LOKAL PRZYJAZNY OSOBOM NIEPEŁNOSPRAWNYM
                                </div>
                            </div>
                        </Col>
                        <Col lg={7}>
                            <div className="contact-iframe">
                                <iframe
                                    width="100%"
                                    height="100%"
                                    frameBorder="0"
                                    scrolling="no"
                                    marginHeight="0"
                                    marginWidth="0"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2393.385362114931!2d23.178136077039955!3d53.1391781902039!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ffea025835c35%3A0x465b083685753486!2sJ%C3%B3zefa%20Ignacego%20Kraszewskiego%2028%2F1%2C%2015-025%20Bia%C5%82ystok!5e0!3m2!1spl!2spl!4v1739782390685!5m2!1spl!2spl"
                                    title="ul. Kraszewskiego 28 lok. U5, 15-025 Białystok"
                                    aria-label="ul. Kraszewskiego 28 lok. U5, 15-025 Białystok">
                                </iframe>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

export default Contact;